@import 'cookieconsent.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
.error-msg {
    @apply absolute -bottom-2 left-0 text-xs text-red-800 w-full text-right block
}

.btn-primary {
    @apply group uppercase relative w-full flex justify-center py-2 px-5 border border-transparent font-medium rounded-md text-white bg-primary hover:bg-secondary hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
}

.btn-outline {
    @apply uppercase py-2 border-primary text-sm font-medium rounded-md text-primary bg-transparent border-2 hover:bg-secondary hover:text-primary hover:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
}

.input-outlined {
    @apply focus:outline-none focus:ring-2 focus:ring-primary block w-full px-4 py-3 border border-base rounded-md;
}

.sidebar-link {
    @apply flex flex-row align-middle justify-start pr-2 pl-4 py-2.5 text-sm hover:bg-theme-bg transition duration-300;
}

.sidebar-link.active {
    @apply bg-gray-100
}

/* Rules for sizing the icon. */
.material-icons.md-18,
.material-icons-outlined.md-18 {
    font-size: 18px;
}

.material-icons.md-24,
.material-icons-outlined.md-24 {
    font-size: 24px;
}

.material-icons.md-36,
.material-icons-outline.md-36 {
    font-size: 36px;
}

.material-icons.md-48,
.material-icons-outline.md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

.input-label {
    @apply block text-sm font-medium;
}

[disabled] {
    opacity: 0.5;
    pointer-events: none;
}
